import { useRouter } from "next/router";

import { getCoreFeatureFlags, getSubportfolios } from "@/js/core/coreResources";

import { useAppContext } from "@/layouts/AppLayout/AppContext";
import DashboardPage from "@/pages/DashboardPage";
import PortfolioPage from "@/pages/PortfolioPage";

export default function () {
  const { featureConfigurations } = useAppContext();
  const router = useRouter();

  // this is LP view, go to reports url
  if (featureConfigurations.TEMP_HIDE_DASHBOARD) {
    router.replace("/reports");

    return null;
  }

  return <DashboardPage />;
}
